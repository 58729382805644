<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/admin/home">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label>
        <router-link :to="{ name: 'user' }">{{ $t("Users") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $route.params.id ? $t("Edit") : $t("Add") }}</label>
    </div>

    <v-container fluid>
      <v-row class="mt-3">
        <v-col md="12">
          <v-alert v-if="id" border="top" color="green lighten-2" dark>
            <h4>{{ $t("EditUser") }}</h4></v-alert
          >
          <v-alert v-else border="top" color="blue lighten-2" dark>
            <h4>{{ $t("AddNewUser") }}</h4></v-alert
          >
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col class="py-0" :cols="6">
          <div
            :class="{
              'has-error': errors.has(`addEditValidation.name`),
            }"
          >
            <label>{{ $t("UserName") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="name"
              data-vv-scope="addEditValidation"
              v-validate="'required'"
              :data-vv-as="$t('UserName')"
              hide-details
              dense
              outlined
              v-model="formData.name"
            ></v-text-field>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.name`)">
            {{ errors.first(`addEditValidation.name`) }}
          </div>
        </v-col>
        <v-col class="py-0" :cols="6">
          <div
            :class="{
              'has-error': errors.has(`addEditValidation.email`),
            }"
          >
            <label>{{ $t("Email") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="email"
              data-vv-scope="addEditValidation"
              v-validate="'required'"
              :data-vv-as="$t('Email')"
              hide-details
              dense
              outlined
              v-model="formData.email"
            ></v-text-field>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.email`)">
            {{ errors.first(`addEditValidation.email`) }}
          </div>
        </v-col>
        <v-col v-if="!id" class="py-0" :cols="6">
          <div
            :class="{
              'has-error': errors.has(`addEditValidation.password`),
            }"
          >
            <label>{{ $t("Password") }}</label>
            <v-text-field
              class="d-block my-2"
              type="password"
              name="password"
              data-vv-scope="addEditValidation"
              v-validate="'required'"
              :data-vv-as="$t('Password')"
              hide-details
              dense
              outlined
              v-model="formData.password"
            ></v-text-field>
          </div>

          <div
            class="help-block"
            v-if="errors.has(`addEditValidation.password`)"
          >
            {{ errors.first(`addEditValidation.password`) }}
          </div>
        </v-col>
        <v-col v-if="!id" class="py-0" :cols="6">
          <div
            :class="{
              'has-error': errors.has(`addEditValidation.confirm_password`),
            }"
          >
            <label>{{ $t("ConfirmPassword") }}</label>
            <v-text-field
              class="d-block my-2"
              type="password"
              name="confirm_password"
              data-vv-scope="addEditValidation"
              v-validate="'required'"
              :data-vv-as="$t('ConfirmPassword')"
              hide-details
              dense
              outlined
              v-model="formData.confirm_password"
            ></v-text-field>
          </div>

          <div
            class="help-block"
            v-if="errors.has(`addEditValidation.confirm_password`)"
          >
            {{ errors.first(`addEditValidation.confirm_password`) }}
          </div>
        </v-col>

        <v-col class="py-0" :cols="6">
          <div
            :class="{
              'has-error': errors.has(`addEditValidation.roles`),
            }"
          >
            <label>{{ $t("Roles") }}</label>
            <v-autocomplete
              class="d-block my-2"
              name="roles"
              data-vv-scope="addEditValidation"
              v-validate="'required'"
              :data-vv-as="$t('Roles')"
              hide-details
              dense
              outlined
              v-model="formData.roles"
              item-value="id"
              item-text="name"
              :items="roleList"
              clearable
              multiple
            ></v-autocomplete>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.roles`)">
            {{ errors.first(`addEditValidation.roles`) }}
          </div>
        </v-col>
      </v-row>

      <v-row class="float-left mt-5 mb-5">
        <v-btn
          @click="save"
          class="mr-2 ml-2"
          color="primary"
          dark
          outlined
          small
        >
          {{ $t("Save") }}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "usersAddEdit",

  data() {
    return {
      id: this.$route.params.id ? this.$route.params.id : null,
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      formData: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        roles: [],
      },
      roleList: [],
    };
  },

  methods: {
      save() {

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        
        let sendData = JSON.parse(JSON.stringify(this.formData));

         if (this.$route.params.id) {
          //edit
          sendData.id = this.$route.params.id;
          this.$store.dispatch(`user/updateData`, sendData).then((res) => {
            this.notifySuccess("Update", res);
            this.$router.push({ name: "user" });
          }).catch(error => {
                this.notifyError('Error',error);

          })
        } else {

          this.$store.dispatch(`user/saveData`, sendData).then((res) => {
            this.notifySuccess("Save", res);
            this.$router.push({ name: "user" });
          }).catch(error => {
            this.notifyError('Error',error);
          })
        }
      });
    },
    loadRoleList() {
      this.$store.dispatch(`user/listRoles`).then(res => {
        this.roleList= res.data;
      }).catch(error => {
                        this.notifyError('Error',error);

          })
    }
  },
  created() {
    this.loadRoleList();
     if (this.$route.params.id) {
      this.$store
        .dispatch(`user/findData`, this.$route.params.id)
        .then((res) => {
          this.formData = res.data;
        });
    }

  }
};
</script>


<style>
.permissionsCard span {
  font-size: 14px;
}
</style>
